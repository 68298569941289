




























































































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import BaseForm from "@/components/form/BaseForm";
import {
  ExpressionOfInterestFormQuestion,
  ExpressionOfInterestFormQuestionType,
} from "@/entity/ExpressionOfInterest";

@Component({
  components: {},
})
export default class EoiEdit extends Mixins(BaseForm) {
  @Prop({ type: Array, required: true }) projectId!: number;
  @Prop({ type: Array, required: true }) versionId!: number;
  @PropSync("questions", { type: Array, required: true })
  questionsLocal!: ExpressionOfInterestFormQuestion[];

  newQuestionType: ExpressionOfInterestFormQuestionType =
    ExpressionOfInterestFormQuestionType.FREE_TEXT;

  isFreeText(questionTypeId: number) {
    return questionTypeId == ExpressionOfInterestFormQuestionType.FREE_TEXT;
  }

  isFreeTextShort(questionTypeId: number) {
    return (
      questionTypeId == ExpressionOfInterestFormQuestionType.FREE_TEXT_SHORT
    );
  }

  isStandardizedAnswers(questionTypeId: number) {
    return (
      questionTypeId ==
      ExpressionOfInterestFormQuestionType.STANDARDIZED_ANSWERS
    );
  }

  isAmount(questionTypeId: number) {
    return questionTypeId == ExpressionOfInterestFormQuestionType.AMOUNT;
  }

  getQuestionType(questionTypeId: number) {
    if (questionTypeId === ExpressionOfInterestFormQuestionType.FREE_TEXT)
      return "Freitext-Frage";
    if (questionTypeId === ExpressionOfInterestFormQuestionType.FREE_TEXT_SHORT)
      return "Freitext-Frage kurz";
    if (questionTypeId === ExpressionOfInterestFormQuestionType.AMOUNT)
      return "CHF";
    if (
      questionTypeId ===
      ExpressionOfInterestFormQuestionType.STANDARDIZED_ANSWERS
    )
      return "Multiple Choice";
    return "";
  }

  removeQuestion(questionId: number) {
    this.questionsLocal = this.questionsLocal
      .filter((item) => item.id !== questionId)
      .sort((a, b) => a.orderNum - b.orderNum)
      .map((item, index) => ({ ...item, orderNum: index }));
  }

  handleQuestionOrder(questionIndex: number, direction: "up" | "down") {
    const arr = [...this.questionsLocal];
    [arr[questionIndex + (direction === "down" ? 1 : -1)], arr[questionIndex]] =
      [
        arr[questionIndex],
        arr[questionIndex + (direction === "down" ? 1 : -1)],
      ];
    this.questionsLocal = arr.map((item, index) => ({
      ...item,
      orderNum: index,
    }));
  }

  addQuestion() {
    const newQuestionId = new Date().getTime();
    this.questionsLocal.push({
      id: newQuestionId,
      createdAt: new Date(),
      projectId: this.projectId,
      versionId: this.versionId,
      questionTypeId: this.newQuestionType,
      orderNum: !this.questionsLocal.length
        ? 0
        : this.questionsLocal[this.questionsLocal.length - 1].orderNum + 1,
      answerOptions:
        this.newQuestionType ===
        ExpressionOfInterestFormQuestionType.STANDARDIZED_ANSWERS
          ? {
              id: new Date().getTime(),
              createdAt: new Date(),
              questionId: newQuestionId,
              answer1: "",
              answer2: "",
              answer3: "",
              answer4: "",
            }
          : null,
    });
  }

  get questionTypeOptions() {
    return [
      {
        value: ExpressionOfInterestFormQuestionType.FREE_TEXT,
        name: "Freitext-Frage",
      },
      {
        value: ExpressionOfInterestFormQuestionType.FREE_TEXT_SHORT,
        name: "Freitext-Frage kurz",
      },
      {
        value: ExpressionOfInterestFormQuestionType.AMOUNT,
        name: "CHF",
      },
      {
        value: ExpressionOfInterestFormQuestionType.STANDARDIZED_ANSWERS,
        name: "Multiple Choice",
      },
    ];
  }
}
