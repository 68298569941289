









































































































































import { Component, Mixins } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import BaseForm from "@/components/form/BaseForm";
import RulesMixin from "@/mixins/RulesMixin";
import CurrencyInput from "@/components/form/input/CurrencyInput.vue";
import ReadOnlyInput from "@/components/form/input/ReadOnlyInput.vue";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";
import {
  calculateProjectSales,
  ProjectSales,
  ProjectSalesCalculationResults,
} from "@/entity/ProjectSales";
import { formatTimestamp } from "@/utils/date";
import ProjectsService from "@/services/ProjectsService";
import { FinanceYears } from "@/entity/Project";
import { numberArrayRange } from "@/utils/array";

@Component({
  components: {
    FormCard,
    CurrencyInput,
    ReadOnlyInput,
    DatePickerWithInput,
  },
})
export default class ProjectSalesFormCard extends Mixins(RulesMixin, BaseForm) {
  financeYears: FinanceYears | null = null;
  isLoadingFinanceYears = false;
  data: ProjectSales = {
    salesPrice: null,
    fixedPrice: null,
    earnOutMax: null,
    ownerLoan: null,
    orderDate: null,
    startDate: null,
    signing: null,
    baseYear: null,
  };

  formatTimestamp(value: string | null): string {
    return value === null ? "" : formatTimestamp(value);
  }

  get calculationResults(): ProjectSalesCalculationResults {
    return calculateProjectSales(this.data);
  }

  get orderDateReadable(): string {
    return this.formatTimestamp(this.data.orderDate);
  }

  get startDateReadable(): string {
    return this.formatTimestamp(this.data.startDate);
  }

  get signingReadable(): string {
    return this.formatTimestamp(this.data.signing);
  }

  async loadFinanceYears(): Promise<void> {
    try {
      this.isLoadingFinanceYears = true;
      this.financeYears = await ProjectsService.getFinanceYears(
        this.entityId as number
      );
    } finally {
      this.isLoadingFinanceYears = false;
    }
  }

  async loadData(): Promise<void> {
    try {
      this.isLoading = true;
      const { projectSales } = await ProjectsService.getSalesData(
        this.entityId as number
      );
      this.data = projectSales;
    } finally {
      this.isLoading = false;
    }
  }

  async submitForm(): Promise<void> {
    await ProjectsService.updateSalesData(this.entityId as number, this.data);
  }

  get baseYearOptions(): number[] {
    if (this.financeYears === null) return [];
    return numberArrayRange(
      this.financeYears.firstYear,
      this.financeYears.lastYear
    );
  }

  get baseYearErrorMessage(): string | null {
    return this.baseYearOptions.length === 0
      ? this.$t("financeDataMissing").toString()
      : null;
  }

  created() {
    if (this.entityId) {
      this.loadData();
    }
    this.loadFinanceYears();
  }
}
