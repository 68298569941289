























































































































import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import Tabs from "@/components/common/Tabs.vue";
import { Tab } from "@/interfaces";
import ProjectsService from "@/services/ProjectsService";
import Project, {
  ProjectStatusTransaction,
  ProjectType,
} from "@/entity/Project";
import { Getter, State } from "vuex-class";
import DialogMixin from "@/mixins/DialogMixin";
import ProjectFormCard from "@/components/project-form/ProjectFormCard.vue";
import ProjectExposeFormCard from "@/components/project-detail/ProjectExposeFormCard.vue";
import ProjectNdaFormCard from "@/components/project-detail/ProjectNdaFormCard.vue";
import ProjectExpressionOfInterestFormCard from "@/components/project-detail/ProjectExpressionOfInterestFormCard.vue";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import User from "@/entity/User";
import ProjectFinanceSelect from "@/components/ProjectFinanceSelect.vue";
import ProjectSalesFormCard from "@/components/project-sales-form/ProjectSalesFormCard.vue";
import { capitalizeStart } from "@/utils/string";

@Component({
  components: {
    Tabs,
    ProjectFormCard,
    ProjectExposeFormCard,
    ActionButtonWithTooltip,
    ProjectFinanceSelect,
    ProjectSalesFormCard,
    ProjectNdaFormCard,
    ProjectExpressionOfInterestFormCard,
  },
})
export default class ProjectLayout extends Mixins(DialogMixin) {
  @Ref("view") view!: any;
  @Prop({ type: Number, required: true }) projectId!: number;

  project: Project | null = null;
  isFetching = false;
  financeActiveTab = "incomeStatement";
  projectSalesUpdateTimestamp = Date.now();

  @State("user", { namespace: "auth" }) loggedInUser!: User;
  @Getter("isAdmin", { namespace: "auth" }) isAdmin!: boolean;

  get tabs(): Tab[] {
    return [
      {
        routeName: "projectDetail",
        name: this.$tc("detail", 1),
      },
      {
        routeName: "projectBuyers",
        name: this.$tc("prospectiveBuyer", 2),
        hidden: this.project?.projectType !== ProjectType.transaction,
      },
      {
        routeName: "projectHistory",
        name: this.$tc("history", 1),
      },
      {
        routeName: "projectStatistics",
        name: this.$tc("statistic", 1),
        hidden: this.project?.projectType !== ProjectType.transaction,
      },
      {
        routeName: "finance",
        name: this.$tc("finance", 1),
      },
      {
        routeName: "projectValuation",
        name: this.$tc("valuation", 1),
        hidden: this.project?.projectType !== ProjectType.valuation,
      },
      {
        routeName: "projectSales",
        name: this.$tc("projectSales.tabTitle", 2),
        hidden:
          this.project?.projectType !== ProjectType.transaction ||
          (this.project?.status !== ProjectStatusTransaction.sold &&
            this.project?.status !== ProjectStatusTransaction.closed),
      },
    ];
  }

  get disabledActionForNonAdmin(): boolean {
    return (
      this.project?.status === ProjectStatusTransaction.closed &&
      !this.project.projectOperations?.hasConsultantRights
    );
  }

  get canUploadFinanceData(): boolean {
    return this.project?.projectOperations?.canUploadFinanceData ?? false;
  }

  get canEditSales(): boolean {
    return this.project?.projectOperations?.canEditSales ?? false;
  }

  get canCloseProject(): boolean {
    return this.project?.projectOperations?.canCloseProject ?? false;
  }

  get projectActiveState(): {
    icon: string;
    tooltip: string;
  } {
    if (this.project?.isDisabled) {
      return {
        icon: "mdi-account-check",
        tooltip: "enable",
      };
    }

    return {
      icon: "mdi-account-remove",
      tooltip: "disable",
    };
  }

  openIncomeStatementDialog(): void {
    this.view.openIncomeStatementDialog();
  }

  get canSendExpose(): boolean {
    return this.project?.projectType === ProjectType.transaction;
  }

  async closeProject(): Promise<void> {
    const response = await this.$confirm(
      this.$t("closeProjectPrompt").toString()
    );

    if (response) {
      await ProjectsService.closeProject(this.projectId);
      await this.fetchProject();
    }
  }

  async handleProjectActiveState(): Promise<void> {
    const response = await this.$confirm(
      this.$t(
        this.project?.isDisabled
          ? "activateProjectPrompt"
          : "deactivateProjectPrompt"
      ).toString()
    );

    if (response) {
      await ProjectsService.handleProjectActivity(this.projectId);
      await this.fetchProject();
    }
  }

  async fetchProject(): Promise<void> {
    try {
      this.isFetching = true;
      this.project = await ProjectsService.findOne(this.projectId);
      this.$replaceBreadcrumb({
        key: ":projectId",
        value: this.project.projectContact.name,
      });
      this.$replaceBreadcrumb({
        key: ":projectType",
        value: capitalizeStart(this.$t(this.project.projectType).toString()),
      });
    } catch (e) {
      await this.$router.push({ name: "404" });
    } finally {
      this.isFetching = false;
    }
  }

  async onProjectSalesFormSuccess(): Promise<void> {
    this.closeDialog();
    this.projectSalesUpdateTimestamp = Date.now();
  }

  shouldRedirectToSales(oldProject: Project, newProject: Project): boolean {
    if (oldProject.projectType !== ProjectType.transaction) return false;

    return (
      oldProject.status !== ProjectStatusTransaction.sold &&
      newProject.status === ProjectStatusTransaction.sold
    );
  }

  onFormSuccess(project: Project): void {
    const shouldRedirectToSales = this.shouldRedirectToSales(
      this.project as Project,
      project
    );
    this.closeDialog();
    this.fetchProject();

    if (shouldRedirectToSales) {
      this.$router.push({
        name: "projectSales",
      });
    }
  }

  created() {
    this.fetchProject();
  }
}
