





























































































import { Component, Mixins } from "vue-property-decorator";
import BTFile from "@/entity/File";
import FileManagerService from "@/services/FileManagerService";
import RulesMixin from "@/mixins/RulesMixin";

@Component
export default class ProjectExposeFormCard extends Mixins(RulesMixin) {
  attachments: BTFile[] = [];
  isInitialLoading = true;
  file: File | null = null;
  isUploading = false;
  isFormValid = false;
  fileBeingDeleted: number | null = null;

  async uploadFile(): Promise<void> {
    try {
      this.isUploading = true;
      await FileManagerService.uploadExposeFile(
        this.$route.params.projectId,
        this.file as File
      );
      this.resetForm();
      this.attachments = await FileManagerService.getExposeFiles(
        this.$route.params.projectId
      );
    } catch (e) {
      if (e.response.data.message === "File size should not exceed 1 MB") {
        this.$snackbarError(this.$tc("fileSizeError"));
      } else if (e.response.data.message) {
        this.$snackbarError(this.$tc("storageUnavalibleError"));
      } else {
        this.$snackbarError(this.$tc("apiErrors.unableToSave"));
      }
    } finally {
      this.isUploading = false;
    }
  }

  resetForm(): void {
    (this.$refs.form as any).reset();
  }

  async deleteFile(fileId: number): Promise<void> {
    try {
      this.fileBeingDeleted = fileId;
      await FileManagerService.deleteExposeFile(fileId);
      this.attachments = await FileManagerService.getExposeFiles(
        this.$route.params.projectId
      );
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.fileBeingDeleted = null;
    }
  }

  async loadAttachments(): Promise<void> {
    try {
      this.attachments = await FileManagerService.getExposeFiles(
        this.$route.params.projectId
      );
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    }
  }

  openFile(fileId: string): void {
    window.open(`${window.location.origin}/api/v2/files/${fileId}`, "_blank");
  }

  async created(): Promise<void> {
    try {
      await this.loadAttachments();
    } finally {
      this.isInitialLoading = false;
    }
  }
}
