import User from "@/entity/User";
import BTFile from "./File";

export interface CreateExpressionOfInterest {
  firstName: string;
  name: string;
  profileUrl: string;
  interestedNote: string;
  intentionsNote: string;
  overtakeNote: string;
  sellerRoleNote: string;
  ownFunds: string;
  bankFinancedAcquisitionExperience: string;
}

export default interface ExpressionOfInterest {
  id?: number;
  firstName: string;
  name: string;
  profileUrl: string;
  interestedNote: string;
  intentionsNote: string;
  overtakeNote: string;
  sellerRoleNote: string;
  ownFunds: string;
  bankFinancedAcquisitionExperience: string;
  version?: number;
  updatedBy: number;
  user: User | null;
}

export interface ExpressionOfInterestFormQuestion {
  id: number;
  projectId: number;
  orderNum: number;
  primaryText?: string | null;
  secondaryText?: string | null;
  createdAt: Date;
  questionTypeId: number;
  answerOptions: ExpressionOfInterestFormAnswerOption | null;
  versionId: number;
}

export interface ExpressionOfInterestFormAnswerOption {
  id: number;
  questionId: number;
  answer1?: string | null;
  answer2?: string | null;
  answer3?: string | null;
  answer4?: string | null;
  createdAt: Date;
}

export enum ExpressionOfInterestFormQuestionType {
  FREE_TEXT = 1,
  STANDARDIZED_ANSWERS = 2,
  AMOUNT = 3,
  FREE_TEXT_SHORT = 4,
}

export interface ExpressionOfInterestEditDTO {
  primaryText: string;
  secondaryText: string;
  questionTypeId: ExpressionOfInterestFormQuestionType;
  orderNum: number;
  answerOptions: string[] | null;
}

export interface ExpressionOfInterestFormAnswer {
  id: number;
  questionId: number;
  buyerId: number;
  answer: string;
  createdAt: Date;
  question: ExpressionOfInterestFormQuestion | null;
}

export interface BuyersExpressionOfInterest {
  questions: ExpressionOfInterestFormQuestion[];
  answers: ExpressionOfInterestFormAnswer[] | null;
  file: BTFile[] | null;
}

export interface ExpressionOfInterestVersion {
  version: number;
  isLocked: boolean;
}
