























import { Component, Vue, PropSync } from "vue-property-decorator";

@Component
export default class ProjectFinanceSelect extends Vue {
  @PropSync("activeTab", { type: String, required: true })
  activeTabLocal!: string;

  get financeSelectOptions() {
    return [
      {
        value: "incomeStatement",
        text: this.$t("incomeStatement"),
        icon: "mdi-finance",
      },
      {
        value: "balanceSheet",
        text: this.$t("balanceSheet"),
        icon: "mdi-scale-balance",
      },
      {
        value: "derivedIndicators",
        text: this.$tc("identificationNumber", 2),
        icon: "mdi-calculator",
      },
    ];
  }
}
