import moment from "moment";

export interface ProjectSales {
  projectId?: number;
  salesPrice: number | null;
  fixedPrice: number | null;
  earnOutMax: number | null;
  ownerLoan: number | null;
  orderDate: string | null;
  startDate: string | null;
  signing: string | null;
  baseYear: number | null;
  calculationResults?: ProjectSalesCalculationResults | null;
}

export interface ProjectSalesCalculationResults {
  fixedPriceRatio: number;
  earnOutMaxPercentage: number;
  ownerLoanPercentage: number;
  duration: number;
}

export interface ProjectTransactionMultiples {
  turnover: number | null;
  netProfit: number | null;
  ebt: number | null;
  ebit: number | null;
  ebitda: number | null;
}

export interface ProjectSalesWithTransactionMultiples {
  projectSales: ProjectSales;
  transactionMultiples: ProjectTransactionMultiples;
}

export function calculateProjectSales(
  data: ProjectSales
): ProjectSalesCalculationResults {
  let fixedPriceRatio = null;
  let earnOutMaxPercentage = null;
  let ownerLoanPercentage = null;
  let duration = null;

  if (data.salesPrice !== null) {
    if (data.fixedPrice !== null) {
      fixedPriceRatio = (data.fixedPrice / data.salesPrice) * 100;
    }

    if (data.earnOutMax !== null) {
      earnOutMaxPercentage = (data.earnOutMax / data.salesPrice) * 100;
    }

    if (data.ownerLoan !== null) {
      ownerLoanPercentage = (data.ownerLoan / data.salesPrice) * 100;
    }
  }

  if (data.signing !== null && data.startDate !== null) {
    const dayDiff = moment(data.signing).diff(moment(data.startDate), "d");
    duration = dayDiff === 0 ? 0 : dayDiff / 30;
  }

  return {
    fixedPriceRatio:
      fixedPriceRatio === null || isNaN(fixedPriceRatio) ? 0 : fixedPriceRatio,
    earnOutMaxPercentage:
      earnOutMaxPercentage === null || isNaN(earnOutMaxPercentage)
        ? 0
        : earnOutMaxPercentage,
    ownerLoanPercentage:
      ownerLoanPercentage === null || isNaN(ownerLoanPercentage)
        ? 0
        : ownerLoanPercentage,
    duration: duration === null || isNaN(duration) ? 0 : duration,
  };
}
